import React from 'react'

import { Link } from 'gatsby'

export const ORIGINS = {
  JOB_APPLICATION: { type: 'Job Application' },
  CANDIDATE_SUGGESTION: { type: 'Candidate Suggestion' },
  ROLE_SUGGESTION: { type: 'Role Suggestion' },
  SEARCH: { type: 'Search' },
  SMARTLIST: { type: 'Smart List' },
  TRANSFER: { type: 'Transfer' },
  REFERRAL: { type: 'Referral' },
  RECRUITER_OUTREACH: { type: 'Recruiter Outreach' },
  SOURCING: { type: 'Sourcing' },
  DIRECT_PROFILE: { type: 'Direct Profile' },
  API: { type: 'API' },
  UNKNOWN: { type: 'Unknown' }
}

export const formatPartnerRoleName = ({
  preposition,
  partnerName,
  roleId,
  roleName
}) =>
  partnerName &&
  roleId &&
  roleName && (
    <>
      {preposition} {partnerName}:{' '}
      <Link to={`/role?id=${roleId}`} data-testid={roleId}>
        {roleName}
      </Link>
    </>
  )
