import type {
  PartnerRoleStage,
  PartnerRoleStageInfo
} from 'redesign/types/PartnerRole'
import type Profile from 'redesign/types/Profile'

export const SEARCH_FILTER_PRESETS = {
  orderByExpiry: 'Order By Expiry',
  orderByExperience: 'Order By Experience',
  reset: 'Reset'
}

export const API_ERROR_MESSAGES = {
  canceledOperation: 'Operation canceled due to new search request.'
}

export const SNOOZE_ENUM = {
  english: 'English',
  not_interested_right_now: 'Not interested right now',
  needs_more_experience: 'Needs more experience',
  other: 'Other'
}

export const SNOOZE_ENUM_OPTIONS = [
  {
    value: 'english',
    label: 'English'
  },
  {
    value: 'not_interested_right_now',
    label: 'Not interested right now'
  },
  {
    value: 'needs_more_experience',
    label: 'Needs more experience'
  },
  {
    value: 'other',
    label: 'Other'
  }
]

export const ROLE_CANDIDATE_REASON_ORIGIN_OPTIONS = [
  { value: 'xteam', label: 'X-Team' },
  { value: 'partner', label: 'Partner' },
  { value: 'candidate', label: 'Candidate' }
]

export const CAVALRY_URLS = [
  'http://localhost:8001',
  'https://xp-cavalry-dev.x-team.com',
  'https://xp-cavalry.x-team.com'
]

export const RATE_RANGE_LABELS = [
  'All',
  '0-35',
  '35-50',
  '50-70',
  '70-100',
  '100+'
]

export const REQUIRED_SKILLS_LIMIT = 4
export const MAXIMUM_SKILLS_LIMIT = 8

export const INVALID_CREDENTIALS = 'Identifier or password invalid.'
export const GOOGLE_BUTTON_TEST_ID = 'google-btn'

export const SKILLS_RENDER_LIMIT = 5
export const MILLISECONDS_IN_A_DAY = 86400000

export const JOB_APPLICATIONS_FILTER_OPTIONS = [
  {
    label: 'Pending',
    value: 'pending'
  },
  {
    label: 'Hidden',
    value: 'hiddenAt'
  },
  {
    label: 'Closed',
    value: 'closed'
  },
  {
    label: 'All',
    value: 'all'
  }
]

export const PROFILE_FIELDS: Partial<Record<keyof Profile, string>> = {
  email: 'Email',
  xteamEmail: 'X-Team Email',
  fullName: 'Full Name',
  about: 'Summary by Candidate',
  summary: 'Summary by Cavalry',
  githubAccount: 'Github',
  linkedinAccount: 'LinkedIn',
  stackoverflowAccount: 'Other (URL)',
  country: 'Country',
  timezoneOffset: 'Timezone Offset',
  rateRange: 'Rate Range',
  referral: 'Referred By',
  utmSource: 'Marketing Source',
  utmMedium: 'Marketing Medium',
  utmCampaign: 'Marketing Campaign',
  utmContent: 'Marketing Content',
  utmTerm: 'Marketing Term',
  benchStatus: 'Internal Project Details Status',
  benchSummary: 'Internal Project Details Summary',
  xhqRate: `Developer's XHQ Rate`,
  minRate: 'Minimum Rate',
  subscribed: 'Subscribed to Job Board Emails',
  jobAlertsNotification: 'Subscribed to Job Alerts Emails',
  preferredJobTypes: 'Preferred Job types',
  preferredSkills: 'Skill Preferences',
  source: 'Source',
  sourcedFrom: 'Sourced From'
}

// Note: the stage IDs are already decided by the backend
export const PARTNER_ROLE_STAGE_INFO: Record<
  PartnerRoleStage,
  PartnerRoleStageInfo
> = {
  Incoming: {
    id: 1,
    title: 'Incoming'
  },
  Shortlisting: {
    id: 2,
    title: 'Shortlisting'
  },
  Interviewing: {
    id: 3,
    title: 'Interviewing'
  },
  XProfiling: {
    id: 4,
    title: 'X-Profiling'
  },
  PresentedToBizDev: {
    id: 5,
    title: 'Presented to BizDev'
  },
  AwaitingPartnerFeedback: {
    id: 6,
    title: 'Awaiting Partner Feedback'
  },
  Closed: {
    id: 7,
    title: 'Closed'
  },
  Searching: {
    id: 8,
    title: 'Searching'
  },
  Paused: {
    id: 9,
    title: 'Paused'
  },
  Done: {
    id: 10,
    title: 'Done'
  },
}

// Stages to display in UI filters, in the correct display order
export const FILTERABLE_PARTNER_ROLE_STAGES: Array<PartnerRoleStage> = [
  'Incoming',
  'Searching',
  'Shortlisting',
  'Interviewing',
  'XProfiling',
  'PresentedToBizDev',
  'AwaitingPartnerFeedback',
  'Paused',
  'Done',
]
