export const handleThrowError = error => {
  console.error(error)
  throw error
}

export const normalizeConfig = config => {
  if (!config?.params) {
    config.params = {}
  }

  return config
}
