import React from 'react'

import { Button as ButtonComponent } from 'antd'

import type { ButtonProps } from './Button.types'

import { primaryButtonStyles } from './Button.module.css'

const Button = ({ children, ...rest }: ButtonProps) => {
  const primaryButtonClassName =
    rest.type === 'primary' ? primaryButtonStyles : null

  return (
    <ButtonComponent
      {...rest}
      className={`${rest.className} ${primaryButtonClassName}`}
    >
      {children}
    </ButtonComponent>
  )
}

export default Button
