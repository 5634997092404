import { handleThrowError } from '../utils/services'

import { request as api } from './strapi/api'

export const listRoleCandidates = async params => {
  try {
    const response = await api.get(`/role-candidates`, { ...params })
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const countRoleCandidates = async params => {
  try {
    const response = await api.get(`/role-candidates/count`, params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const updateRoleCandidate = async (roleCandidateId, values) => {
  try {
    const response = await await api.put(
      `/role-candidates/${roleCandidateId}`,
      values
    )
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const deleteRoleCandidate = async roleCandidateId => {
  try {
    const response = await api.delete(`/role-candidates/${roleCandidateId}`)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const pitchRoleCandidate = async roleCandidate => {
  try {
    const response = await api.post(`/role-candidates/pitch`, roleCandidate)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const bookRoleCandidate = async roleCandidate => {
  try {
    const response = await api.post(`/role-candidates/book`, roleCandidate)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const editRoleCandidateBooking = async ({ roleCandidateId, bookedStartAt, bookedEndAt }) => {
  try {
    return await api.put(`/role-candidates/${roleCandidateId}/book`, {
      bookedStartAt,
      bookedEndAt
    })
  } catch (e) {
    handleThrowError(e)
  }
}

export const reserveRoleCandidate = async roleCandidate => {
  try {
    const response = await api.post(`/role-candidates/reserve`, roleCandidate)
    return response
  } catch (e) {
    handleThrowError(e?.response?.data)
  }
}

export const shortlistRoleCandidate = async roleCandidate => {
  try {
    const response = await api.post(`/role-candidates/shortlist`, roleCandidate)
    return response
  } catch (e) {
    handleThrowError(e?.response?.data)
  }
}

export const closeRoleCandidate = async roleCandidate => {
  try {
    const response = await api.post(`/role-candidates/close`, roleCandidate)
    return response
  } catch (e) {
    handleThrowError(e?.response?.data)
  }
}

export const transferReservation = async ({
  roleCandidateId,
  newPartnerRoleId,
  origin
}) => {
  try {
    return await api.post(`/role-candidates/${roleCandidateId}/transfer`, {
      newPartnerRoleId,
      origin
    })
  } catch (e) {
    handleThrowError(e)
  }
}

export const contactRoleCandidate = async ({
  roleCandidateId,
  contactedBy
}) => {
  try {
    return await api.post(`/role-candidates/contact/${roleCandidateId}`, {
      contactedBy
    })
  } catch (e) {
    handleThrowError(e)
  }
}

export const requestXProfileForRoleCandidate = async ({
  roleCandidateId,
  xProfileRequestedBy
}) => {
  try {
    return await api.post(
      `/role-candidates/${roleCandidateId}/request-x-profile`,
      {
        xProfileRequestedBy
      }
    )
  } catch (e) {
    handleThrowError(e)
  }
}

export const presentToBizdev = async ({
  roleCandidateId,
  xProfile,
  presentedToBizdevBy
}) => {
  try {
    return await api.post(
      `/role-candidates/${roleCandidateId}/present-to-bizdev`,
      {
        xProfile,
        presentedToBizdevBy
      }
    )
  } catch (e) {
    handleThrowError(e)
  }
}

export const addRoleCandidateAssigneesService = async ({ roleCandidateId, assignees }) => {
  try {
    return await api.post(`/role-candidates/${roleCandidateId}/add-assignees`,
      { userIds: assignees },
    )
  } catch (e) {
    handleThrowError(e)
  }
}

export const removeRoleCandidateAssigneesService = async ({ roleCandidateId, assignees }) => {
  try {
    return await api.post(`/role-candidates/${roleCandidateId}/remove-assignees`,
      { userIds: assignees },
    )
  } catch (e) {
    handleThrowError(e)
  }
}

export const clientInterviewRoleCandidateService = async ({ roleCandidateId }) => {
  try {
    const response = await api.post(`/role-candidates/${roleCandidateId}/client-interview`)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const acceptRoleCandidateService = async ({ roleCandidateId }) => {
  try {
    const response = await api.post(`/role-candidates/${roleCandidateId}/accept`)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}
