import axios from 'axios'

import { isPlainObject } from '../../utils/helpers'
import { httpRetry } from '../../utils/http-retry'

import { setupUncaughtErrorHandler } from './error-handler'

export const ACCESS_ERROR =
  'Your account does not have sufficient access privileges.'
const AUTH_TOKEN_KEY = 'authToken'
const baseUrl = process.env.GATSBY_API_URL

const localStorage = typeof window !== 'undefined' ? window.localStorage : {}

setupUncaughtErrorHandler()

axios.interceptors.response.use(undefined, httpRetry(axios))

export const isCancel = axios.isCancel

export const CancelToken = axios.CancelToken

const _cacheBusterByType = {}

export function setCacheBusterLookup (data) {
  Object.assign(
    _cacheBusterByType,
    data
  )
}

export function getCacheBuster (type) {
  return _cacheBusterByType[type]
}

export function getAuthToken () {
  return localStorage[AUTH_TOKEN_KEY]
}

export function initAuthHeader (token) {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  } else {
    delete axios.defaults.headers.common.Authorization
  }
}

export function setAuthToken (token) {
  if (token) {
    localStorage.setItem(AUTH_TOKEN_KEY, token)
  } else {
    localStorage.removeItem(AUTH_TOKEN_KEY)
  }

  initAuthHeader(token)
}

export async function checkAuth (token) {
  return await axios({
    url: `${baseUrl}/users/me`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
}

export async function signIn ({ identifier, password }) {
  try {
    const response = await unauthenticated.post(`/auth/local`, {
      identifier,
      password
    })

    const { data, status, headers } = response
    const { canAccessCavalryApp } = data.user.role.capabilities || {}

    // Ensure we only let Cavalry and Manager users in
    if (!canAccessCavalryApp) {
      return {
        data: null,
        error: ACCESS_ERROR
      }
    }

    setAuthToken(data.jwt)

    return {
      data: {
        user: data.user
      },
      headers,
      status
    }
  } catch (error) {
    if (!error.response) {
      return error
    }

    const { status } = error.response
    if (status !== 400) {
      console.error('Unknown error:', error)
    }

    return error.response
  }
}

export function signOut () {
  setAuthToken(null)
}

export async function requestPasswordReset ({ email }) {
  try {
    const response = await unauthenticated.post(`/auth/forgot-password`, {
      email
    })

    const { data, status, headers } = response

    return {
      data,
      headers,
      status
    }
  } catch (error) {
    if (!error.response) {
      return error
    }

    const { status } = error.response
    if (status !== 400) {
      console.error('Unknown error:', error)
    }

    return error.response
  }
}

export async function resetPassword ({ code, password, passwordConfirmation }) {
  try {
    const response = await unauthenticated.post(`/auth/reset-password`, {
      code,
      password,
      passwordConfirmation
    })

    const { data, status, headers } = response

    return {
      data,
      headers,
      status
    }
  } catch (error) {
    return handleAPIError(error)
  }
}

// Make sure that all paths follow the same schema:
// - begin with a slash
function normalizePath (path = '') {
  return path[0] === '/' ? path : `/${path}`
}

// Make sure request methods accepts correct params.
// ref: https://github.com/axios/axios#request-method-aliases
function normalizeRequestArgs (method, data, config) {
  const createUpdateMethods = ['post', 'put', 'patch']
  const readDeleteMethods = ['get', 'delete']

  const retryConfig = { initialDelayMs: 3000, retries: config?.retries ?? 4 }
  if (createUpdateMethods.includes(method)) {
    return [data, { ...config, ...retryConfig }]
  }

  if (readDeleteMethods.includes(method)) {
    // `data` serves as a request config here
    // ref: https://github.com/axios/axios#request-config
    return [{ ...data, ...retryConfig }]
  }
}

export function request (method, path, data, config) {
  return axios[method](
    `${baseUrl}${normalizePath(path)}`,
    ...normalizeRequestArgs(method, data, config)
  )
}

export function unauthenticated (method, path, data) {
  return axios[method](
    `${baseUrl}${normalizePath(path)}`,
    ...normalizeRequestArgs(method, {
      ...data,
      headers: {
        ...(data.headers || {}),
        Authorization: ''
      }
    })
  )
}

const methods = ['get', 'post', 'put', 'delete']

methods.forEach(
  method =>
    (request[method] = (path, data, config = {}) =>
      request(method, path, data, config))
)

methods.forEach(
  method =>
    (unauthenticated[method] = (path, data) =>
      unauthenticated(method, path, data))
)

export function handleAPIError (error) {
  const errResponse = error.response

  if (!errResponse) {
    return error
  }

  const { status, statusText } = errResponse

  // If the JWT token is invalid, we should clear out the token from the LS
  if (status === 401) {
    setAuthToken(null)
  }

  if (status !== 400) {
    console.error('Unknown error:', error)
  }

  // 'status' and 'statusText' are being exposed for convenience to be
  // used by the callers deciding whether the call was successful or not.
  return {
    status,
    statusText,
    error:
      status !== 200
        ? getValidationErrors(errResponse)
        : { name: `${status}: ${statusText}` }
  }
}

// Centralized handling of possible Strapi server validation errors
// We can get validation error in the form of the deeply nested
// array or an object.
// If either of those is missing, the error is sent back
// in a top-level `data.message` prop.
function getValidationErrors (error) {
  // Depending on HTTP error type, Strapi exposes errors differently
  const errorPayload = error.data.data || error.data.message

  if (isPlainObject(errorPayload)) {
    return errorPayload
  }

  if (Array.isArray(errorPayload)) {
    return { name: errorPayload[0].messages[0].message }
  }

  return { name: error.data.message }
}
