import React from 'react'

import { OfflineErrorPage } from '../OfflineErrorPage'

import { useNetworkInformation } from '../../hooks/useNetworkInformation'

const NetworkInformationWrapper = ({ children }) => {
  const { isOnline } = useNetworkInformation()

  if (!isOnline) {
    return <OfflineErrorPage />
  }

  return children
}

export default NetworkInformationWrapper
