import { handleThrowError, normalizeConfig } from '../utils/services'

import { unauthenticated as api } from './strapi/api'

export const listFeatureFlags = async (config = {}) => {
  try {
    normalizeConfig(config)
    const response = await api.get('/feature-flags', config)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}
