import React from 'react'

import { Button } from '../Button'
import { Result } from '../Result'

import { stylesOfflinePage } from './OfflineErrorPage.module.css'

const OfflineErrorPage = () => {
  const handleReload = () => {
    window.location.reload()
  }

  return (
    <div className={stylesOfflinePage}>
      <Result
        status="404"
        title="No Internet Connection."
        subTitle="Please check your internet settings and reload the page."
        extra={
          <Button type="primary" onClick={handleReload}>
            Reload
          </Button>
        }
      />
    </div>
  )
}

export default OfflineErrorPage
