const exponentialDelay = (retryCount = 0) => {
  if (retryCount === 0) {
    return 0
  }

  const retryDelay = 2 ** retryCount * 100
  const randomSum = Math.floor(Math.random() * 3000) // 0-30% of the delay
  return retryDelay + randomSum
}

export const httpRetry = axios => error => {
  const { config } = error
  const isRetryableError =
    config?.retries && Boolean(error.response) && error.response.status === 503

  if (!isRetryableError) {
    return Promise.reject(error)
  }

  config.__retryCount = config.__retryCount || 0

  // Only retry if we didn't reach the limit
  if (config.__retryCount >= config.retries) {
    return Promise.reject(error)
  }

  const backoff = new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, (config.initialDelayMs += exponentialDelay(config.__retryCount)))
  })

  config.__retryCount += 1

  return backoff.then(() => axios(config))
}
