/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import type { GatsbyBrowser } from 'gatsby'

import { NetworkInformationWrapper } from './src/components/NetworkInformationWrapper'
import { FeatureFlagsContextController } from './src/modules/feature-flags/feature-flags-context-controller'
import { ErrorProvider } from './src/providers/ErrorProvider'

dayjs.extend(utc)

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element
}) => (
  <NetworkInformationWrapper>
    <FeatureFlagsContextController>
      <ErrorProvider>
        {element}
      </ErrorProvider>
    </FeatureFlagsContextController>
  </NetworkInformationWrapper>
)

export default { wrapRootElement }
