import React, { useEffect, useState, useMemo } from 'react'

import { listFeatureFlags } from '../../services/featureFlags'
import { handleAPIError, getAuthToken } from '../../services/strapi/api'

import { FeatureFlagsContext } from './feature-flags-context'
import type {
  FeatureFlags,
  FeatureFlagsContextControllerProps,
  FetchFeatureFlagsResponse,
} from './feature-flags.types'

let fetchFeatureFlags = () => {
  const promise = listFeatureFlags({
    headers: {
      Authorization: ''
    }
  })

  // Memoize
  fetchFeatureFlags = () => promise

  return promise
}

export const FeatureFlagsContextController = ({
  children,
  shouldGetAuthToken = true
}: FeatureFlagsContextControllerProps) => {
  const [features, setFeatures] = useState<FeatureFlags>()

  useEffect(() => {
    const loadFeatureFlags = async () => {
      const token = getAuthToken()

      if (!token && shouldGetAuthToken) {
        setFeatures({})
        return
      }

      try {
        const response: FetchFeatureFlagsResponse = await fetchFeatureFlags()
        const enabled: FeatureFlags = {}
        response.data.forEach(f => (enabled[f.flag] = f.enabled))
        setFeatures(enabled)
      } catch (error) {
        setFeatures({})
        handleAPIError(error)
      }
    }

    loadFeatureFlags()
  }, [shouldGetAuthToken])

  const contextValue = useMemo(
    () => ({
      features
    }),
    [features]
  )

  return (
    <FeatureFlagsContext.Provider value={contextValue}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}
