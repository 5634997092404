import { API_ERROR_MESSAGES, CAVALRY_URLS } from '../../utils/constants'
import { isSSR } from '../../utils/helpers'

export const setupUncaughtErrorHandler = () => {
  if (!isSSR) {
    window.addEventListener('unhandledrejection', event => {
      if (event.origin !== undefined && !CAVALRY_URLS.includes(event.origin)) {
        return
      }
      console.warn(
        `Unhandled rejection with "${event.reason}" reason happened.`
      )
      // Prevent the rejection from appearing in the console
      if (event.reason.message === API_ERROR_MESSAGES.canceledOperation) {
        event.preventDefault()
      }
    })
  }
}
